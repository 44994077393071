.App {
  text-align: center;
}

body {
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-height: 800px) { 
  .custom-container-one { 
      display: flex;
      flex-direction: column;
      min-height: 850px;
      justify-content: baseline;
      /* background-color: grey; */
  }
}

@media (max-height: 700px) { 
  .custom-container-two { 
      display: flex;
      flex-direction: column;
      min-height: 700px;
      justify-content: baseline;
      /* background-color: grey; */
  }
}

@media (max-height: 700px) { 
  .custom-container-three { 
      display: flex;
      flex-direction: column;
      min-height: 500px;
      justify-content: baseline;
      /* background-color: grey; */
  }
}

@media (max-height: 700px), (min-width:1200px) { 
  .custom-container-four{ 
      display: flex;
      flex-direction: column;
      min-height: 700px;
      justify-content: baseline;
      /* background-color: grey; */
  }
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}
.homeImgTabBorderWrapper .tab:focus::before {
  content: "";
  border: 1px dashed #b95804 !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}
.custom-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropDownContainer {
  box-shadow: 0px 4px 30px 0px #00000012;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 140px;
  height: auto;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 50;
  overflow-y: scroll;
}
