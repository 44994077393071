@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    --primary: 39.5, 89.8%, 57.8%;
    --primary-foreground: 0, 0%, 0%;
    --secondary: 0, 0%, 0%, 1;
    --secondary-foreground: 0, 100%, 100%, 1;
    --link-primary: 28, 77.8%, 45.9%;
    --text-paragraph: #4a4f55;
  }
  input {
    font-family: 'Gotham-Book';
  }
}
* {
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
}

body {
  color: rgb(var(--foreground-rgb));
}
html > body {
  padding: 0 !important;
  margin: 0 !important;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@layer components {
  /* body, */
  /* p {
    @apply font-sans text-[14px] text-[#4A4F55] leading-[1.42857143];
  }
  h2 {
    @apply font-bold text-[24px] xs:text-[32px] md:text-[24px] xl:text-[32px] mb-[20px] xs:mb-[24px] leading-[1.3];
  }
  h3 {
    @apply font-bold text-[18px] xs:text-[24px] md:text-[20px] lg:text-[22px] xl:text-[24px] leading-[1.4];
  } */

  .dot-list-parent ul li,
  .dot-list li {
    @apply mb-[20px] relative text-[#4A4F55] pl-[20px] leading-[20px];
  }
  .dot-list-parent ul li,
  .dot-list li {
    @apply before:content-[''] before:absolute before:bg-[#f4b233] before:w-[10px] before:h-[10px] before:rounded-[50%]
     before:top-[5px] before:left-[0px];
    -webkit-print-color-adjust: exact;
  }
  .dot-list-parent ul li a,
  .dot-list a {
    @apply text-[#B95804] block;
  }

  .slick-dots {
    @apply bottom-[-35px];
  }
  /* Pagination.css */
  .pagination-container {
    @apply flex mt-[20px];
  }
  body .login-btn-group {
    @apply h-auto leading-normal text-[14px] text-center tracking-[0.28px] font-sans font-bold py-[5px]  px-[7px] w-[100%] rounded-[5px] uppercase;
  }

  /* styles.css */
  .HovercardPanelContent {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    right: 0;
    animation-duration: 700ms;
    overflow-y: auto;
    animation-timing-function: ease;
  }

  .HovercardPanelContent[data-state="open"] {
    animation-name: enterFromLeft;
  }
  .HovercardPanelContent[data-state="closed"] {
    animation-name: exitToLeft;
  }
  @keyframes enterFromLeft {
    from {
      transition: ease-in 0.45s;
      transform: translateX(100%);
    }
    to {
      transition: ease-in 0.45s;
      transform: translateX(0);
    }
  }

  @keyframes exitToLeft {
    0% {
      transition: ease-out 0.45s;
      transform: translateX(0);
    }
    100% {
      transition: ease-out 0.45s;
      transform: translateX(100%);
    }
  }
}
@font-face {
  font-family: "Gotham-Book";
  src: url("./lib/fonts/Gotham-Book.otf") format("OpenType");

  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("./lib/fonts/Gotham-Bold.otf") format("OpenType");

  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Medium";
  src: url("./lib/fonts/Gotham-Medium.otf") format("OpenType");

  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Black";
  src: url("./lib/fonts/Gotham-Black.otf") format("OpenType");

  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Leviathan-HTF-Black";
  src: url("./lib//fonts/Leviathan-HTF-Black.ttf") format("OpenType");

  font-style: normal;
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
:focus-visible,
:focus {
  outline: -webkit-focus-ring-color auto 0px;
  outline: 0px dashed #b95804 !important;
}
